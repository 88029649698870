import axios from '@/common/axios'
import qs from 'qs'

export function addPressureRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/pressureRecord/add',
    data: qs.stringify(data)
  })
}

export function deletePressureRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/pressureRecord/delete/' + id
  })
}

export function updatePressureRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/pressureRecord/update',
    data: qs.stringify(data)
  })
}

export function selectPressureRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/pressureRecord/info/' + id
  })
}

export function selectPressureRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/pressureRecord/list',
    params: query
  })
}
