<template>
  <div id="pressureRecord">
    <el-dialog
      :title="pressureRecordFormTitle"
      width="680px"
      :visible.sync="pressureRecordDialogVisible"
      :close-on-click-modal="false"
      @close="pressureRecordDialogClose"
    >
      <el-form
        ref="pressureRecordFormRef"
        :model="pressureRecordForm"
        :rules="pressureRecordFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="监测标准" prop="standard">
              <el-input v-model="pressureRecordForm.standard" placeholder="请输入监测标准" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监测日期" prop="testDate">
              <el-date-picker v-model="pressureRecordForm.testDate" placeholder="请选择监测日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="压差" prop="pressure">
              <el-input v-model="pressureRecordForm.pressure" placeholder="请输入压差" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="检测结果" prop="result">
              <el-radio-group v-model="pressureRecordForm.result">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="2">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="记录人" prop="recorder">
              <el-input v-model="pressureRecordForm.recorder" placeholder="请输入记录人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="pressureRecordForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="pressureRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="pressureRecordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="记录人">
        <el-input v-model="searchForm.recorder" placeholder="请输入记录人" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="pressureRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="standard" label="监测标准" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.testDate">{{ scope.row.testDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pressure" label="压差" />
      <el-table-column label="检测结果">
        <template slot-scope="scope">
          <span v-if="scope.row.result === 1">符合</span>
          <span v-if="scope.row.result === 2">不符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="pressureRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addPressureRecord,
  deletePressureRecord,
  updatePressureRecord,
  selectPressureRecordInfo,
  selectPressureRecordList
} from '@/api/quality/pressureRecord'

export default {
  data () {
    return {
      pressureRecordDialogVisible: false,
      pressureRecordFormTitle: '',
      pressureRecordForm: {
        id: '',
        standard: '',
        testDate: '',
        pressure: '',
        result: '',
        recorder: '',
        remarks: ''
      },
      pressureRecordFormRules: {
        recorder: [{ required: true, message: '记录人不能为空', trigger: ['blur', 'change']}]
      },
      pressureRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        recorder: ''
      }
    }
  },
  created () {
    selectPressureRecordList(this.searchForm).then(res => {
      this.pressureRecordPage = res
    })
  },
  methods: {
    pressureRecordDialogClose () {
      this.$refs.pressureRecordFormRef.resetFields()
    },
    pressureRecordFormSubmit () {
      if (this.pressureRecordFormTitle === '压差监测记录详情') {
        this.pressureRecordDialogVisible = false
        return
      }
      this.$refs.pressureRecordFormRef.validate(async valid => {
        if (valid) {
          if (this.pressureRecordFormTitle === '新增压差监测记录') {
            this.pressureRecordForm.id = ''
            await addPressureRecord(this.pressureRecordForm)
          } else if (this.pressureRecordFormTitle === '修改压差监测记录') {
            await updatePressureRecord(this.pressureRecordForm)
          }
          this.pressureRecordPage = await selectPressureRecordList(this.searchForm)
          this.pressureRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.pressureRecordFormTitle = '新增压差监测记录'
      this.pressureRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePressureRecord(row.id)
        if (this.pressureRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.pressureRecordPage = await selectPressureRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.pressureRecordFormTitle = '修改压差监测记录'
      this.pressureRecordDialogVisible = true
      this.selectPressureRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.pressureRecordFormTitle = '压差监测记录详情'
      this.pressureRecordDialogVisible = true
      this.selectPressureRecordInfoById(row.id)
    },
    selectPressureRecordInfoById (id) {
      selectPressureRecordInfo(id).then(res => {
        this.pressureRecordForm.id = res.id
        this.pressureRecordForm.standard = res.standard
        this.pressureRecordForm.testDate = res.testDate
        this.pressureRecordForm.pressure = res.pressure
        this.pressureRecordForm.result = res.result
        this.pressureRecordForm.recorder = res.recorder
        this.pressureRecordForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPressureRecordList(this.searchForm).then(res => {
        this.pressureRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPressureRecordList(this.searchForm).then(res => {
        this.pressureRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPressureRecordList(this.searchForm).then(res => {
        this.pressureRecordPage = res
      })
    }
  }
}
</script>

<style>
</style>
